var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "layer" }, [
      _c("div", { staticClass: "layer-img" }, [
        _c("img", { attrs: { src: require("../../assets/images/img.png") } })
      ]),
      _c("div", { staticClass: "line" }, [
        _c("div", [_vm._v("姓名：")]),
        _c("div", { staticClass: "line-right" }, [_vm._v("郑京京")])
      ]),
      _c("div", { staticClass: "line" }, [
        _c("div", [_vm._v("性别：")]),
        _c("div", { staticClass: "line-right" }, [_vm._v("女")])
      ]),
      _c("div", { staticClass: "line" }, [
        _c("div", [_vm._v("发证日期：")]),
        _c("div", { staticClass: "line-right" }, [_vm._v("2020-02-25")])
      ]),
      _c("div", { staticClass: "line" }, [
        _c("div", [_vm._v("资格证书号码：")]),
        _c("div", { staticClass: "line-right" }, [
          _vm._v("21009111010580002020003077")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }