var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "disease" },
        [
          _c(
            "van-swipe",
            {
              staticClass: "my-swipe",
              attrs: { autoplay: 3000, "indicator-color": "white" }
            },
            [
              _c("van-swipe-item", [
                _c("img", {
                  attrs: {
                    src: require("../../../src/assets/images/banner04.png")
                  }
                })
              ])
            ],
            1
          ),
          _c("div", { staticClass: "tips-top", on: { click: _vm.onLayer } }, [
            _vm._v("以下内容由专业保险经纪人提供，点击查看资格证")
          ]),
          _vm.$route.query.type
            ? _c("div", { staticClass: "tab-box" }, [
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "list-top" }, [_vm._v("基本信息")]),
                  _c("div", { staticClass: "list list-box" }, [
                    _c(
                      "div",
                      { staticStyle: { width: "50%", "text-align": "center" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "500",
                              color: "#1F64EC",
                              "font-size": ".28rem",
                              "margin-bottom": ".25rem"
                            }
                          },
                          [_vm._v(_vm._s(_vm.gender))]
                        ),
                        _c("div", [_vm._v("性别")])
                      ]
                    ),
                    _c(
                      "div",
                      { staticStyle: { width: "50%", "text-align": "center" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "500",
                              color: "#1F64EC",
                              "font-size": ".28rem",
                              "margin-bottom": ".25rem"
                            }
                          },
                          [_vm._v(_vm._s(_vm.birthday))]
                        ),
                        _c("div", [_vm._v("出生日期")])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "list-top" }, [_vm._v("疾病情况")]),
                  _c("div", { staticClass: "list list-box" }, [
                    _c("div", { staticStyle: { "text-align": "center" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "500",
                            color: "#1F64EC",
                            "font-size": ".28rem",
                            "margin-bottom": ".25rem"
                          }
                        },
                        [_vm._v(_vm._s(_vm.basic.disease_selected))]
                      ),
                      _c("div", [_vm._v("疾病症状")])
                    ]),
                    _c("div", { staticStyle: { "text-align": "center" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "500",
                            color: "#1F64EC",
                            "font-size": ".28rem",
                            "margin-bottom": ".25rem"
                          }
                        },
                        [_vm._v(_vm._s(_vm.basic.bi_rads))]
                      ),
                      _c("div", [_vm._v("Bi-RADS")])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "list-top" }, [
                    _vm._v("疾病进展分析")
                  ]),
                  _c("div", { staticClass: "list" }, [
                    _c("div", [_vm._v("恶化可能性")]),
                    _c("div", { staticStyle: { "margin-top": ".2rem" } }, [
                      _vm.basic.bi_rads == "0级"
                        ? _c("div", [
                            _vm._v(
                              "评估不完全，需要补充其他影像学检查进一步评估。"
                            )
                          ])
                        : _vm._e(),
                      _vm.basic.bi_rads == "1级"
                        ? _c("div", [_vm._v("阴性，未见异常。")])
                        : _vm._e(),
                      _vm.basic.bi_rads == "2级"
                        ? _c("div", [
                            _vm._v(
                              "良性病灶，基本上可以排除恶性病变，根据年龄及临床表现可行6-12个月随诊。"
                            )
                          ])
                        : _vm._e(),
                      _vm.basic.bi_rads == "3级"
                        ? _c("div", [
                            _vm._v(
                              "可能良性病灶，恶性危险性应该小于2%，建议短期（3-6个月）复查及其他进一步检查。"
                            )
                          ])
                        : _vm._e(),
                      _c("div", [
                        _vm.basic.bi_rads == "4a级"
                          ? _c("p", [
                              _vm._v(
                                "BI-RADS 4a ：更倾向于良性可能，恶性率＜10%；"
                              )
                            ])
                          : _vm._e(),
                        _vm.basic.bi_rads == "4b级"
                          ? _c("p", [
                              _vm._v(
                                "BI-RADS 4b ：难以明确良恶性，恶性率＜50%；"
                              )
                            ])
                          : _vm._e(),
                        _vm.basic.bi_rads == "4c级"
                          ? _c("p", [
                              _vm._v(
                                "BI-RADS 4c ：恶性可能性较高，恶性率＞50%；"
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm.basic.bi_rads == "5级"
                        ? _c("div", [
                            _vm._v(
                              "高度可能恶性，应积极采取适当的诊断及处理措施。"
                            )
                          ])
                        : _vm._e(),
                      _vm.basic.bi_rads == "6级"
                        ? _c("div", [_vm._v("已经活检证实为恶性。")])
                        : _vm._e()
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": ".2rem",
                          "font-size": ".26rem"
                        }
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticStyle: { color: "#1675FF" },
                            attrs: { to: "tubercle" }
                          },
                          [_vm._v("预估疾病进展及未来主要治疗药物和费用》")]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c("div", { staticClass: "list-top" }, [
                      _vm._v("资金保障方案 "),
                      _c("span", { on: { click: _vm.onChange } }, [
                        _c("img", {
                          attrs: { src: require("../../assets/images/hyh.png") }
                        }),
                        _vm._v(" 换一换")
                      ])
                    ]),
                    _vm._l(_vm.xianzhongshow, function(item) {
                      return _c("div", { staticClass: "list" }, [
                        _c("div", {}, [
                          _c("div", { staticClass: "list-tip" }, [
                            _c("div", [_vm._v("险种")]),
                            _c("div", [_vm._v(_vm._s(item.name))])
                          ]),
                          _c("div", { staticClass: "list-tip" }, [
                            _c("div", [_vm._v("投保年龄")]),
                            _c("div", [_vm._v(_vm._s(item.age))])
                          ]),
                          item.insuredAmount
                            ? _c("div", { staticClass: "list-tip" }, [
                                _c("div", [_vm._v("保额")]),
                                _c("div", [_vm._v(_vm._s(item.insuredAmount))])
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "list-tip" }, [
                            _c("div", [_vm._v("保障时间")]),
                            _c("div", [_vm._v(_vm._s(item.time))])
                          ]),
                          _c("div", { staticClass: "list-tip" }, [
                            _c("div", { staticStyle: { width: "30%" } }, [
                              _vm._v("免赔额")
                            ]),
                            _c("div", [_vm._v(_vm._s(item.deductible))])
                          ]),
                          _c("div", { staticClass: "list-tip" }, [
                            _c("div", [_vm._v("等待期")]),
                            _c("div", [_vm._v(_vm._s(item.waitingPeriod))])
                          ])
                        ]),
                        _c("div", { staticClass: "baozhang" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("方案选择：")
                          ]),
                          _c("div", [_vm._v(" " + _vm._s(item.plan) + " ")])
                        ]),
                        _c("div", { staticClass: "baozhang" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("保障内容：")
                          ]),
                          _c("div", [_vm._v(" " + _vm._s(item.center) + " ")])
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#C4C4C4",
                              "font-size": ".24rem"
                            }
                          },
                          [
                            _vm._v(
                              "免责声明：本页面的治疗方案及药物等信息来源于国家临床指南，但仅作为预估治疗费用的数据，不得作为患者诊疗方案的建议，本平台不承担诊疗行为相关的任何责任。本页面的资金保障方案中的产品来自网络公开信息，仅作参考。不得作为投资购买建议。"
                            )
                          ]
                        )
                      ])
                    })
                  ],
                  2
                )
              ])
            : _c("div", { staticClass: "tab-box" }, [
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "list-top" }, [_vm._v("基本信息")]),
                  _c("div", { staticClass: "list list-box" }, [
                    _c(
                      "div",
                      { staticStyle: { width: "50%", "text-align": "center" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "500",
                              color: "#1F64EC",
                              "font-size": ".28rem",
                              "margin-bottom": ".25rem"
                            }
                          },
                          [_vm._v(_vm._s(_vm.gender))]
                        ),
                        _c("div", [_vm._v("性别")])
                      ]
                    ),
                    _c(
                      "div",
                      { staticStyle: { width: "50%", "text-align": "center" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "500",
                              color: "#1F64EC",
                              "font-size": ".28rem",
                              "margin-bottom": ".25rem"
                            }
                          },
                          [_vm._v(_vm._s(_vm.birthday))]
                        ),
                        _c("div", [_vm._v("出生日期")])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "list-top" }, [_vm._v("疾病情况")]),
                  _c("div", { staticClass: "list list-box" }, [
                    _vm.basic.disease_selected
                      ? _c("div", { staticStyle: { "text-align": "center" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                color: "#1F64EC",
                                "font-size": ".28rem",
                                "margin-bottom": ".25rem"
                              }
                            },
                            [_vm._v(_vm._s(_vm.basic.disease_selected))]
                          ),
                          _c("div", [_vm._v("疾病类型")])
                        ])
                      : _vm._e(),
                    _vm.basic.pathological_staging
                      ? _c("div", { staticStyle: { "text-align": "center" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                color: "#1F64EC",
                                "font-size": ".28rem",
                                "margin-bottom": ".25rem"
                              }
                            },
                            [_vm._v(_vm._s(_vm.basic.pathological_staging))]
                          ),
                          _c("div", [_vm._v("分期")])
                        ])
                      : _vm._e(),
                    _vm.basic.pathological_type
                      ? _c("div", { staticStyle: { "text-align": "center" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                color: "#1F64EC",
                                "font-size": ".28rem",
                                "margin-bottom": ".25rem"
                              }
                            },
                            [_vm._v(_vm._s(_vm.basic.pathological_type))]
                          ),
                          _c("div", [_vm._v("分型")])
                        ])
                      : _vm._e(),
                    _vm.basic.operation_method
                      ? _c("div", { staticStyle: { "text-align": "center" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-weight": "500",
                                color: "#1F64EC",
                                "font-size": ".28rem",
                                "margin-bottom": ".25rem"
                              }
                            },
                            [_vm._v(_vm._s(_vm.basic.operation_method))]
                          ),
                          _c("div", [_vm._v("手术方式")])
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "box" }, [
                  _c("div", { staticClass: "list-top" }, [
                    _vm._v("疾病进展分析")
                  ]),
                  _c("div", { staticClass: "list" }, [
                    _c("div", { staticClass: "line" }, [
                      _c("div", [_vm._v("5年生存率")]),
                      _c("div", [_vm._v(_vm._s(_vm.survivalRate))])
                    ]),
                    _c("div", { staticClass: "line" }, [
                      _c("div", [_vm._v("复发转移可能性")]),
                      _c("div", [_vm._v(_vm._s(_vm.recrudescence))])
                    ]),
                    _c("div", { staticClass: "list-box" }, [
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "div",
                          { staticStyle: { "font-size": "20px" } },
                          [_c("svg-icon", { attrs: { "icon-class": "fuke" } })],
                          1
                        ),
                        _c("div", [_vm._v("子宫内膜增厚")])
                      ]),
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "div",
                          { staticStyle: { "font-size": "20px" } },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "xueguanwaike" }
                            })
                          ],
                          1
                        ),
                        _c("div", [_vm._v("静脉血栓")])
                      ]),
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "div",
                          { staticStyle: { "font-size": "20px" } },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "gandanwaike" }
                            })
                          ],
                          1
                        ),
                        _c("div", [_vm._v("药物性肝损伤")])
                      ]),
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "div",
                          { staticStyle: { "font-size": "20px" } },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "shenjingwaike" }
                            })
                          ],
                          1
                        ),
                        _c("div", [_vm._v("更年期综合征")])
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": ".2rem",
                          "font-size": ".26rem"
                        }
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticStyle: { color: "#1675FF" },
                            attrs: { to: "diseaseKnowledge" }
                          },
                          [_vm._v("预估疾病进展及未来主要治疗药物和费用》")]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c("div", { staticClass: "list-top" }, [
                      _vm._v("资金保障方案 "),
                      _c("span", { on: { click: _vm.onChange } }, [
                        _c("img", {
                          attrs: { src: require("../../assets/images/hyh.png") }
                        }),
                        _vm._v(" 换一换")
                      ])
                    ]),
                    _vm._l(_vm.xianzhongshow, function(item) {
                      return _c("div", { staticClass: "list" }, [
                        _c("div", {}, [
                          _c("div", { staticClass: "list-tip" }, [
                            _c("div", [_vm._v("险种")]),
                            _c("div", [_vm._v(_vm._s(item.name))])
                          ]),
                          _c("div", { staticClass: "list-tip" }, [
                            _c("div", [_vm._v("投保年龄")]),
                            _c("div", [_vm._v(_vm._s(item.age))])
                          ]),
                          item.insuredAmount
                            ? _c("div", { staticClass: "list-tip" }, [
                                _c("div", [_vm._v("保额")]),
                                _c("div", [_vm._v(_vm._s(item.insuredAmount))])
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "list-tip" }, [
                            _c("div", [_vm._v("保障时间")]),
                            _c("div", [_vm._v(_vm._s(item.time))])
                          ]),
                          _c("div", { staticClass: "list-tip" }, [
                            _c("div", { staticStyle: { width: "30%" } }, [
                              _vm._v("免赔额")
                            ]),
                            _c("div", [_vm._v(_vm._s(item.deductible))])
                          ]),
                          _c("div", { staticClass: "list-tip" }, [
                            _c("div", [_vm._v("等待期")]),
                            _c("div", [_vm._v(_vm._s(item.waitingPeriod))])
                          ])
                        ]),
                        _c("div", { staticClass: "baozhang" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("方案选择：")
                          ]),
                          _c("div", [_vm._v(" " + _vm._s(item.plan) + " ")])
                        ]),
                        _c("div", { staticClass: "baozhang" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("保障内容：")
                          ]),
                          _c("div", [_vm._v(" " + _vm._s(item.center) + " ")])
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#C4C4C4",
                              "font-size": ".24rem"
                            }
                          },
                          [
                            _vm._v(
                              "免责声明：本页面的治疗方案及药物等信息来源于国家临床指南，但仅作为预估治疗费用的数据，不得作为患者诊疗方案的建议，本平台不承担诊疗行为相关的任何责任。本页面的资金保障方案中的产品来自网络公开信息，仅作参考。不得作为投资购买建议。"
                            )
                          ]
                        )
                      ])
                    })
                  ],
                  2
                )
              ]),
          _vm.layerShow
            ? _c(
                "div",
                { staticClass: "layer-box" },
                [
                  _c("layer"),
                  _c(
                    "div",
                    { staticClass: "close", on: { click: _vm.close } },
                    [
                      _c("van-icon", {
                        attrs: { name: "close", color: "#fff", size: "30" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }