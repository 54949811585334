// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/layer.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".layer[data-v-4af28274] {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n  background-size: 100% 100%;\n  width: 5.68rem;\n  height: 6.44rem;\n  margin: 3rem auto 0 auto;\n  padding: 0  .2rem;\n}\n.layer-img[data-v-4af28274] {\n  width: 100%;\n  height: 3.6rem;\n  text-align: center;\n  padding-top: .53rem;\n}\n.layer-img img[data-v-4af28274] {\n    width: 70px;\n    height: 135px;\n}\n.line[data-v-4af28274] {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  line-height: .6rem;\n  color: #C4C4C4;\n  font-size: .24rem;\n}\n.line .line-right[data-v-4af28274] {\n    color: #131313;\n}\n", ""]);
// Exports
module.exports = exports;
