<template>
  <div class="disease" v-if="show">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item><img src="../../../src/assets/images/banner04.png"></van-swipe-item>
    </van-swipe>
    <div class="tips-top" @click="onLayer">以下内容由专业保险经纪人提供，点击查看资格证</div>
    <div class="tab-box" v-if="$route.query.type">
      <div class="box">
        <div class="list-top">基本信息</div>
        <div class="list list-box" >
          <div style="width: 50%; text-align: center">
            <div style="font-weight: 500;color: #1F64EC; font-size: .28rem; margin-bottom: .25rem">{{gender}}</div>
            <div>性别</div>
          </div>
          <div style="width: 50%; text-align: center">
            <div style="font-weight: 500;color: #1F64EC; font-size: .28rem;margin-bottom: .25rem">{{birthday}}</div>
            <div>出生日期</div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="list-top">疾病情况</div>
        <div class="list list-box">
          <div style="text-align: center"  >
            <div style="font-weight: 500;color: #1F64EC; font-size: .28rem; margin-bottom: .25rem">{{basic.disease_selected}}</div>
            <div>疾病症状</div>
          </div>
          <div style="text-align: center" >
            <div style="font-weight: 500;color: #1F64EC; font-size: .28rem;margin-bottom: .25rem">{{basic.bi_rads}}</div>
            <div>Bi-RADS</div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="list-top">疾病进展分析</div>
        <div class="list">
            <div>恶化可能性</div>
            <div style="margin-top: .2rem">
              <div v-if="basic.bi_rads == '0级'">评估不完全，需要补充其他影像学检查进一步评估。</div>
              <div v-if="basic.bi_rads == '1级'">阴性，未见异常。</div>
              <div v-if="basic.bi_rads == '2级'">良性病灶，基本上可以排除恶性病变，根据年龄及临床表现可行6-12个月随诊。</div>
              <div v-if="basic.bi_rads == '3级'">可能良性病灶，恶性危险性应该小于2%，建议短期（3-6个月）复查及其他进一步检查。</div>
              <div >
<!--                <p>可疑的恶性病灶，恶性危险性介于2%~94%，可再细分成4a、4b、4c三个亚级。</p>-->
                <p v-if="basic.bi_rads == '4a级'">BI-RADS 4a ：更倾向于良性可能，恶性率＜10%；</p>
                <p v-if="basic.bi_rads == '4b级'">BI-RADS 4b ：难以明确良恶性，恶性率＜50%；</p>
                <p v-if="basic.bi_rads == '4c级'">BI-RADS 4c ：恶性可能性较高，恶性率＞50%；</p>
              </div>
              <div v-if="basic.bi_rads == '5级'">高度可能恶性，应积极采取适当的诊断及处理措施。</div>
              <div v-if="basic.bi_rads == '6级'">已经活检证实为恶性。</div>
            </div>
            <div style="margin-top: .2rem; font-size: .26rem"><router-link to="tubercle" style="color: #1675FF">预估疾病进展及未来主要治疗药物和费用》</router-link></div>
        </div>
      </div>
      <div class="box">
        <div class="list-top">资金保障方案 <span @click="onChange"><img src="../../assets/images/hyh.png"> 换一换</span></div>
        <div class="list"  v-for="item in xianzhongshow">
          <div class="">
            <div class="list-tip"><div>险种</div><div>{{item.name}}</div></div>
            <div class="list-tip"><div>投保年龄</div><div>{{item.age}}</div></div>
            <div class="list-tip" v-if="item.insuredAmount"><div>保额</div><div>{{item.insuredAmount}}</div></div>
            <div class="list-tip"><div>保障时间</div><div>{{item.time}}</div></div>
            <div class="list-tip"><div style="width: 30%">免赔额</div><div>{{item.deductible}}</div></div>
            <div class="list-tip"><div>等待期</div><div>{{item.waitingPeriod}}</div></div>
          </div>
          <div class="baozhang">
            <div class="title">方案选择：</div>
            <div>
              {{item.plan}}
            </div>
          </div>
          <div class="baozhang">
            <div class="title">保障内容：</div>
            <div>
             {{item.center}}
            </div>
          </div>
          <div style="color: #C4C4C4;font-size: .24rem">免责声明：本页面的治疗方案及药物等信息来源于国家临床指南，但仅作为预估治疗费用的数据，不得作为患者诊疗方案的建议，本平台不承担诊疗行为相关的任何责任。本页面的资金保障方案中的产品来自网络公开信息，仅作参考。不得作为投资购买建议。</div>
        </div>
      </div>
    </div>
    <div class="tab-box" v-else>
      <div class="box">
        <div class="list-top">基本信息</div>
        <div class="list list-box" >
          <div style="width: 50%; text-align: center">
            <div style="font-weight: 500;color: #1F64EC; font-size: .28rem; margin-bottom: .25rem">{{gender}}</div>
            <div>性别</div>
          </div>
          <div style="width: 50%; text-align: center">
            <div style="font-weight: 500;color: #1F64EC; font-size: .28rem;margin-bottom: .25rem">{{birthday}}</div>
            <div>出生日期</div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="list-top">疾病情况</div>
        <div class="list list-box">
          <div style="text-align: center"  v-if="basic.disease_selected">
            <div style="font-weight: 500;color: #1F64EC; font-size: .28rem; margin-bottom: .25rem">{{basic.disease_selected}}</div>
            <div>疾病类型</div>
          </div>
          <div style="text-align: center" v-if="basic.pathological_staging">
            <div style="font-weight: 500;color: #1F64EC; font-size: .28rem;margin-bottom: .25rem">{{basic.pathological_staging}}</div>
            <div>分期</div>
          </div>
          <div style=" text-align: center" v-if="basic.pathological_type">
            <div style="font-weight: 500;color: #1F64EC; font-size: .28rem;margin-bottom: .25rem">{{basic.pathological_type}}</div>
            <div>分型</div>
          </div>
          <div style=" text-align: center" v-if="basic.operation_method">
            <div style="font-weight: 500;color: #1F64EC; font-size: .28rem;margin-bottom: .25rem">{{basic.operation_method}}</div>
            <div>手术方式</div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="list-top">疾病进展分析</div>
        <div class="list" >
          <div class="line">
            <div>5年生存率</div>
            <div>{{survivalRate}}</div>
          </div>
          <div class="line">
            <div>复发转移可能性</div>
            <div>{{recrudescence}}</div>
          </div>
          <div class="list-box">
            <div style=" text-align: center">
              <div style="font-size: 20px"><svg-icon icon-class="fuke"></svg-icon></div>
              <div>子宫内膜增厚</div>
            </div>
            <div style=" text-align: center">
              <div style="font-size: 20px"><svg-icon icon-class="xueguanwaike"></svg-icon></div>
              <div>静脉血栓</div>
            </div>
            <div style=" text-align: center">
              <div style="font-size: 20px"><svg-icon icon-class="gandanwaike"></svg-icon></div>
              <div>药物性肝损伤</div>
            </div>
            <div style=" text-align: center">
              <div style="font-size: 20px"><svg-icon icon-class="shenjingwaike"></svg-icon></div>
              <div>更年期综合征</div>
            </div>
          </div>
          <div style="margin-top: .2rem; font-size: .26rem"><router-link to="diseaseKnowledge" style="color: #1675FF">预估疾病进展及未来主要治疗药物和费用》</router-link></div>
        </div>

      </div>
      <div class="box">
        <div class="list-top">资金保障方案 <span @click="onChange"><img src="../../assets/images/hyh.png"> 换一换</span></div>
        <div class="list"  v-for="item in xianzhongshow">
          <div class="">
            <div class="list-tip"><div>险种</div><div>{{item.name}}</div></div>
            <div class="list-tip"><div>投保年龄</div><div>{{item.age}}</div></div>
            <div class="list-tip" v-if="item.insuredAmount"><div>保额</div><div>{{item.insuredAmount}}</div></div>
            <div class="list-tip"><div>保障时间</div><div>{{item.time}}</div></div>
            <div class="list-tip"><div style="width: 30%">免赔额</div><div>{{item.deductible}}</div></div>
            <div class="list-tip"><div>等待期</div><div>{{item.waitingPeriod}}</div></div>
          </div>
          <div class="baozhang">
            <div class="title">方案选择：</div>
            <div>
              {{item.plan}}
            </div>
          </div>
          <div class="baozhang">
            <div class="title">保障内容：</div>
            <div>
              {{item.center}}
            </div>
          </div>
          <div style="color: #C4C4C4;font-size: .24rem">免责声明：本页面的治疗方案及药物等信息来源于国家临床指南，但仅作为预估治疗费用的数据，不得作为患者诊疗方案的建议，本平台不承担诊疗行为相关的任何责任。本页面的资金保障方案中的产品来自网络公开信息，仅作参考。不得作为投资购买建议。</div>
        </div>
      </div>
    </div>
    <div class="layer-box" v-if="layerShow">
      <layer />
      <div class="close" @click="close"><van-icon name="close" color="#fff"  size="30" /></div>
    </div>
  </div>
</template>

<script>
import {sessionState} from "../../api";
import layer from './layer'
export default {
  name: 'disease',
  data() {
    return {
      layerShow:false,
      timer:null,
      count:0,
      gender:"",
      birthday:'',
      basic:{},
      show:false,
      survivalRate:"",
      recrudescence:"",
      answer:"",
      dataBox:[
        {
          type:'AAA',
          plan:"一旦疾病进展，预估药品（按照AC-T方案）年总花费28万余元。患者如果有职工医保的情况下自费费用也高达17万元（医保乙类按照30%的比例自费），对您和家庭都是很大的压力。",
          tabData:[
            {
              fanganmingcheng:"AC-T",
              yaopin:"多柔比星（多美素）",
              liacheng:"21",
              meiliaofeiyong:"11200",
              zongfeiyong:"44800",
              yibao:"否，全部自费"
            },
            {
              fanganmingcheng:"AC-T",
              yaopin:"环磷酰胺（安道生）",
              liacheng:"21",
              meiliaofeiyong:"440",
              zongfeiyong:"1760",
              yibao:"是，医保甲类"
            },  {
              fanganmingcheng:"AC-T",
              yaopin:"序惯紫杉醇（克艾力）",
              liacheng:"21",
              meiliaofeiyong:"11900",
              zongfeiyong:"47600",
              yibao:"是，医保乙类"
            }],
          hualiao:[
            {
              fanganmingcheng:"ddAC-ddT",
              yaopin:"密集型蒽环类+环磷酰胺序贯密集型紫杉醇",
              liacheng:"14",
              meiliaofeiyong:"2.4万元",
              zongfeiyong:"9.6万元",
            },
            {
              fanganmingcheng:"AC",
              yaopin:"蒽环类+环磷酰胺",
              liacheng:"21",
              meiliaofeiyong:"1.2万元",
              zongfeiyong:"4.8万元",
            },
            {
              fanganmingcheng:"TC",
              yaopin:"多西他赛+环磷酰胺",
              liacheng:"21",
              meiliaofeiyong:"1.2万元",
              zongfeiyong:"4.8万元",
            }
          ],
          fangliao:[
            {
              huanzhe:"导管原位癌",
              tuijian:"全乳放疗±瘤床加量"
            },
            {
              huanzhe:"浸润癌，腋窝淋巴结阴性",
              tuijian:"1、全乳放疗（常规或大分割（首选））±瘤床加量；\n" +
                  "2、部分乳腺短程照射（APBI）"
            },
            {
              huanzhe:"浸润癌，腋窝淋巴结阳性，已清扫",
              tuijian:"全乳放疗+瘤床加量+区域淋巴结放疗"
            },
            {
              huanzhe:"浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫",
              tuijian:"全乳放疗（乳房高位切线野）+瘤床加量"
            },
            {
              huanzhe:"浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫",
              tuijian:""
            },
          ]
        },
        {
          type:'BAA',
          plan:"一旦疾病进展，预估药品（按照AC-T方案）年总花费28万余元。患者如果有职工医保的情况下自费费用也高达17万元（医保乙类按照30%的比例自费），对您和家庭都是很大的压力。",
          tabData:[
            {
              fanganmingcheng:"AC-T",
              yaopin:"多柔比星（多美素）",
              liacheng:"21",
              meiliaofeiyong:"11200",
              zongfeiyong:"44800",
              yibao:"否，全部自费"
            },
            {
              fanganmingcheng:"AC-T",
              yaopin:"环磷酰胺（安道生）",
              liacheng:"21",
              meiliaofeiyong:"440",
              zongfeiyong:"1760",
              yibao:"是，医保甲类"
            },  {
              fanganmingcheng:"AC-T",
              yaopin:"序惯紫杉醇（克艾力）",
              liacheng:"21",
              meiliaofeiyong:"11900",
              zongfeiyong:"47600",
              yibao:"是，医保乙类"
            }],
          hualiao:[
            {
              fanganmingcheng:"ddAC-ddT",
              yaopin:"密集型蒽环类+环磷酰胺序贯密集型紫杉醇",
              liacheng:"14",
              meiliaofeiyong:"2.4万元",
              zongfeiyong:"9.6万元",
            },
            {
              fanganmingcheng:"AC",
              yaopin:"蒽环类+环磷酰胺",
              liacheng:"21",
              meiliaofeiyong:"1.2万元",
              zongfeiyong:"4.8万元",
            },
            {
              fanganmingcheng:"TC",
              yaopin:"多西他赛+环磷酰胺",
              liacheng:"21",
              meiliaofeiyong:"1.2万元",
              zongfeiyong:"4.8万元",
            }
          ],
          fangliao:[
            {
              huanzhe:" 腋窝淋巴结清扫术后\n" +
                  "1、T3—T4\n" +
                  "2、腋窝淋巴结阳性",
              tuijian:"胸壁+区域淋巴结放疗"
            },
            {
              huanzhe:"前哨淋巴结阳性，未行腋窝淋巴结清扫",
              tuijian:"胸壁+包括腋窝在内的区域淋巴结放疗"
            },
          ]
        },
        {
          type:'AAB',
          plan:"一旦疾病进展，预估药品（按照AC-T方案）年总花费28万余元。患者如果有职工医保的情况下自费费用也高达17万元（医保乙类按照30%的比例自费），对您和家庭都是很大的压力。",
          tabData:[
            {
              fanganmingcheng:"AC-T",
              yaopin:"多柔比星（多美素）",
              liacheng:"21",
              meiliaofeiyong:"11200",
              zongfeiyong:"44800",
              yibao:"否，全部自费"
            },
            {
              fanganmingcheng:"AC-T",
              yaopin:"环磷酰胺（安道生）",
              liacheng:"21",
              meiliaofeiyong:"440",
              zongfeiyong:"1760",
              yibao:"是，医保甲类"
            },  {
              fanganmingcheng:"AC-T",
              yaopin:"序惯紫杉醇（克艾力）",
              liacheng:"21",
              meiliaofeiyong:"11900",
              zongfeiyong:"47600",
              yibao:"是，医保乙类"
            }],
          hualiao:[
            {
              fanganmingcheng:"ddAC-ddT",
              yaopin:"密集型蒽环类+环磷酰胺序贯密集型紫杉醇",
              liacheng:"14",
              meiliaofeiyong:"2.4万元",
              zongfeiyong:"9.6万元",
            },
            {
              fanganmingcheng:"AC",
              yaopin:"蒽环类+环磷酰胺",
              liacheng:"21",
              meiliaofeiyong:"1.2万元",
              zongfeiyong:"4.8万元",
            },
            {
              fanganmingcheng:"TC",
              yaopin:"多西他赛+环磷酰胺",
              liacheng:"21",
              meiliaofeiyong:"1.2万元",
              zongfeiyong:"4.8万元",
            }
          ],
          fangliao:[
            {
              huanzhe:"导管原位癌",
              tuijian:"全乳放疗±瘤床加量"
            },
            {
              huanzhe:"浸润癌，腋窝淋巴结阴性",
              tuijian:"1、全乳放疗（常规或大分割（首选））±瘤床加量\n" +
                  "2、部分乳腺短程照射（APBI）"
            },
            {
              huanzhe:"浸润癌，腋窝淋巴结阳性，已清扫",
              tuijian:"全乳放疗+瘤床加量+区域淋巴结放疗"
            },
            {
              huanzhe:"浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫",
              tuijian:"全乳放疗（乳房高位切线野）+瘤床加量"
            },
            {
              huanzhe:"浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫",
              tuijian:""
            },
          ],
          juejingqian:{
            chushi:[
              {
                huanzhe:"低复发风险患者（全满足）\n" +
                    "1、淋巴结阴性\n" +
                    "2、G1\n" +
                    "3、T≤2cm\n" +
                    "4、Ki-67低表达（≤30%）",
                tuijian:"TAM5年"
              },
              {
                huanzhe:"满足以下任意条件者\n" +
                "1、G2或G3\n" +
                "2、淋巴结阳性\n"+
                "3、T>2cm",
                tuijian:"OFS+TAM5年"
              },
              {
                huanzhe:"淋巴结阳性>=4个患者",
                tuijian:"OFS+AI5年"
              },
            ],
            yanchang:[
              {
                huanzhe:"完成TAM5年初始治疗且耐受良好患者",
                tuijian:"1、未绝经患者：延长TAM治疗至10年\n" +
                    "2、绝经患者：序贯AI治疗5年"
              },
              {
                huanzhe:"完成OFS+TAM5年初始治疗且耐受良好患者",
                tuijian:"绝经患者：序贯AI治疗"
              },
              {
                huanzhe:"完成OFS+TAM5年初始治疗且耐受良好患者",
                tuijian:"绝经患者：序贯AI治疗"
              },
              {
                huanzhe:"完成OFS+AI5年初始治疗且耐受良好患者",
                tuijian:"绝经患者：AI治疗"
              }
            ]
          },
          juejinghou:{
            chushi:[
              {
                huanzhe:"全部",
                tuijian:"1、芳香化酶抑制剂（AI）5年" +
                    "2、初始使用枸橼酸他莫昔芬（TAM）治疗，可换用芳香化酶抑制剂（AI）5年"
              }
            ],
            yanchang:[
              {
                huanzhe:"完成AI5年初始治疗且耐受良好低危患者",
                tuijian:" 可停药"
              },
              {
                huanzhe:"完成AI5年初始治疗且耐受良好患者，存在以下情况：\n" +
                    "（1）淋巴结阳性\n" +
                    "（2）G3\n" +
                    "（3）其他需要行辅助化疗的危险因素，如KI-67＞30%",
                tuijian:" 可停药"
              }
            ]
          }
        },
        {
          type:'BAB',
          plan:"一旦疾病进展，预估药品（按照AC-T方案）年总花费28万余元。患者如果有职工医保的情况下自费费用也高达17万元（医保乙类按照30%的比例自费），对您和家庭都是很大的压力。",
          tabData:[
            {
              fanganmingcheng:"AC-T",
              yaopin:"多柔比星（多美素）",
              liacheng:"21",
              meiliaofeiyong:"11200",
              zongfeiyong:"44800",
              yibao:"否，全部自费"
            },
            {
              fanganmingcheng:"AC-T",
              yaopin:"环磷酰胺（安道生）",
              liacheng:"21",
              meiliaofeiyong:"440",
              zongfeiyong:"1760",
              yibao:"是，医保甲类"
            },  {
              fanganmingcheng:"AC-T",
              yaopin:"序惯紫杉醇（克艾力）",
              liacheng:"21",
              meiliaofeiyong:"11900",
              zongfeiyong:"47600",
              yibao:"是，医保乙类"
            }],
          hualiao:[
            {
              fanganmingcheng:"ddAC-ddT",
              yaopin:"密集型蒽环类+环磷酰胺序贯密集型紫杉醇",
              liacheng:"14",
              meiliaofeiyong:"2.4万元",
              zongfeiyong:"9.6万元",
            },
            {
              fanganmingcheng:"AC",
              yaopin:"蒽环类+环磷酰胺",
              liacheng:"21",
              meiliaofeiyong:"1.2万元",
              zongfeiyong:"4.8万元",
            },
            {
              fanganmingcheng:"TC",
              yaopin:"多西他赛+环磷酰胺",
              liacheng:"21",
              meiliaofeiyong:"1.2万元",
              zongfeiyong:"4.8万元",
            }
          ],
          fangliao:[
            {
              huanzhe:"腋窝淋巴结清扫术后\n" +
                  "1、T3—T4\n" +
                  "2、腋窝淋巴结阳性",
              tuijian:"胸壁+区域淋巴结放疗"
            },
            {
              huanzhe:"前哨淋巴结阳性，未行腋窝淋巴结清扫",
              tuijian:"胸壁+包括腋窝在内的区域淋巴结放疗"
            },
          ],
          juejingqian:{
            chushi:[
              {
                huanzhe:"低复发风险患者（全满足）\n" +
                    "1、淋巴结阴性\n" +
                    "2、G1\n" +
                    "3、T≤2cm\n" +
                    "4、Ki-67低表达（≤30%）",
                tuijian:"TAM5年"
              },
              {
                huanzhe:"满足以下任意条件者\n" +
                    "1、G2或G3\n" +
                    "2、淋巴结阳性\n" +
                    "3、T>2cm",
                tuijian:"OFS+TAM5年"
              },
              {
                huanzhe:"淋巴结阳性>=4个患者",
                tuijian:"OFS+AI5年"
              },
            ],
            yanchang:[
              {
                huanzhe:" 完成TAM5年初始治疗且耐受良好患者",
                tuijian:"1、未绝经患者：延长TAM治疗至10年\n" +
                    "2、绝经患者：序贯AI治疗5年"
              },
              {
                huanzhe:"完成OFS+TAM5年初始治疗且耐受良好患者",
                tuijian:"绝经患者：序贯AI治疗"
              },
              {
                huanzhe:"完成OFS+AI5年初始治疗且耐受良好患者",
                tuijian:"绝经患者：AI治疗"
              }
            ]
          },
          juejinghou:{
            chushi:[
              {
                huanzhe:"全部",
                tuijian:"1、AI5年\n" +
                    "2、初始使用TAM治疗，可换用AI5年"
              }
            ],
            yanchang:[
              {
                huanzhe:"完成AI5年初始治疗且耐受良好低危患者",
                tuijian:" 可停药"
              },
              {
                huanzhe:"完成AI5年初始治疗且耐受良好患者，存在以下情况：\n" +
                    "（1）淋巴结阳性\n" +
                    "（2）G3\n" +
                    "（3）其他需要行辅助化疗的危险因素，如KI-67＞30%",
                tuijian:" 继续AI治疗"
              }
            ]
          }
        },
        {
          type:'BBA',
          plan:"一旦疾病进展，预估药品（AC-THP）年总花费43万余元。患者如果有职工医保的情况下自费费用11万元（医保乙类按照30%的比例自费），使用T-DM1的患者年总花费更高达65万元，对您和家庭都是较大压力。",
          center:' HER2阳性乳腺癌是一种特殊亚型。如果选择化疗的基础上接受靶向治疗，患者的复发风险降低了40%左右，死亡风险降低了近30%。\n' +
              '针对全切术后，Her2阳性，激素受体阴性的患者\n' +
              '未行辅助治疗患者的可能用药及费用\n' +
              '如腋窝淋巴结阳性：',
          tabData:[
            {
              fanganmingcheng:"AC-T",
              yaopin:"多柔比星（多美素）",
              liacheng:"21",
              meiliaofeiyong:"11200",
              zongfeiyong:"44800",
              yibao:"否，全部自费"
            },
            {
              fanganmingcheng:"AC-T",
              yaopin:"环磷酰胺（安道生）",
              liacheng:"21",
              meiliaofeiyong:"440",
              zongfeiyong:"1760",
              yibao:"是，医保甲类"
            },  {
              fanganmingcheng:"AC-T",
              yaopin:"序惯紫杉醇（克艾力）",
              liacheng:"7",
              meiliaofeiyong:"11900",
              zongfeiyong:"142800",
              yibao:"是，医保乙类，部分自费"
            }, {
              fanganmingcheng:"AC-T",
              yaopin:"曲妥珠单抗",
              liacheng:"21",
              meiliaofeiyong:"7600",
              zongfeiyong:"30400",
              yibao:"是，医保乙类，部分自费"
            }, {
              fanganmingcheng:"AC-T",
              yaopin:"帕妥珠单抗",
              liacheng:"21",
              meiliaofeiyong:"4955",
              zongfeiyong:"24775",
              yibao:"是，医保乙类，部分自费"
            }],
          hualiao:[
            {
              huanzhe:"腋窝淋巴结阳性",
              fanganmingcheng:"AC-THP",
              yaopin:"蒽环类+环磷酰胺序贯多西他赛+曲妥珠单抗+帕妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"2.4万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性",
              fanganmingcheng:"TCbHP",
              yaopin:"多西他赛+卡铂+曲妥珠单抗+帕妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"3.3万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性，伴以下危险因素之一：\n" +
                  "1、T>2cm\n" +
                  "2、其他危险因素，如ER阴性",
              fanganmingcheng:"AC-TH",
              yaopin:"蒽环类+环磷酰胺序贯多西他赛+曲妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"2.9万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性，伴以下危险因素之一：\n" +
                  "1、T>2cm\n" +
                  "2、其他危险因素，如ER阴性",
              fanganmingcheng:"TCbH",
              yaopin:"多西他赛+卡铂+曲妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"3.0万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性且T<2cm",
              fanganmingcheng:"TC+H",
              yaopin:"多西他赛+环磷酰胺+曲妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"2.7万元",
              zongfeiyong:"",
            }
          ],
          fangliao:[
            {
              huanzhe:"腋窝淋巴结清扫术后\n" +
                  "1、T3—T4\n" +
                  "2、腋窝淋巴结阳性",
              tuijian:"胸壁+区域淋巴结放疗"
            },
            {
              huanzhe:"前哨淋巴结阳性，未行腋窝淋巴结清扫",
              tuijian:"胸壁+包括腋窝在内的区域淋巴结放疗"
            },
          ],
          xinfuzhu:[
            {
              fanganmingcheng:"T-DM1",
              liacheng:"21",
              meiliaofeiyong:"45000",
              zongfeiyong:"65万",
              yibao:"否，全部自费"
            }
          ]
        },
        {
          type:'ABA',
          plan:"一旦疾病进展，预估药品（AC-THP）年总花费43万余元。患者如果有职工医保的情况下自费费用11万元（医保乙类按照30%的比例自费），使用T-DM1的患者年总花费更高达65万元，对您和家庭都是很大的压力。",
          center:'HER2阳性乳腺癌是一种特殊亚型。如果选择化疗的基础上接受靶向治疗，患者的复发风险降低了40%左右，死亡风险降低了近30%。\n' +
              '针对保乳术后，Her2阳性，激素受体阴性的患者\n' +
              '未行辅助治疗患者的可能用药及费用\n' +
              '如腋窝淋巴结阳性：',
          tabData:[
            {
              fanganmingcheng:"AC-THP",
              yaopin:"多柔比星（多美素）",
              liacheng:"21",
              meiliaofeiyong:"11200",
              zongfeiyong:"44800",
              yibao:"否，全部自费"
            },
            {
              fanganmingcheng:"AC-THP",
              yaopin:"环磷酰胺（安道生）",
              liacheng:"21",
              meiliaofeiyong:"440",
              zongfeiyong:"1760",
              yibao:"是，医保甲类"
            },  {
              fanganmingcheng:"AC-THP",
              yaopin:"序惯紫杉醇（克艾力）",
              liacheng:"7",
              meiliaofeiyong:"11900",
              zongfeiyong:"142800",
              yibao:"是，医保乙类，部分自费"
            }, {
              fanganmingcheng:"AC-THP",
              yaopin:"曲妥珠单抗",
              liacheng:"21",
              meiliaofeiyong:"7600",
              zongfeiyong:"30400",
              yibao:"是，医保乙类，部分自费"
            }, {
              fanganmingcheng:"AC-THP",
              yaopin:"帕妥珠单抗",
              liacheng:"21",
              meiliaofeiyong:"4955",
              zongfeiyong:"24775",
              yibao:"是，医保乙类，部分自费"
            }],
          hualiao:[
            {
              huanzhe:"腋窝淋巴结阳性",
              fanganmingcheng:"AC-THP",
              yaopin:"蒽环类+环磷酰胺序贯多西他赛+曲妥珠单抗+帕妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"3.5万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性",
              fanganmingcheng:"TCbHP",
              yaopin:"多西他赛+卡铂+曲妥珠单抗+帕妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"3.3万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性，伴以下危险因素之一：\n" +
                  "1、T>2cm\n" +
                  "2、其他危险因素，如ER阴性",
              fanganmingcheng:"AC-TH",
              yaopin:"蒽环类+环磷酰胺序贯多西他赛+曲妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"2.9万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性，伴以下危险因素之一：\n" +
                  "1、T>2cm\n" +
                  "2、其他危险因素，如ER阴性",
              fanganmingcheng:"TCbH",
              yaopin:"多西他赛+卡铂+曲妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"3.0万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性且T<2cm",
              fanganmingcheng:"TC+H",
              yaopin:"多西他赛+环磷酰胺+曲妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"2.7万元",
              zongfeiyong:"",
            }
          ],
          fangliao:[
            {
              huanzhe:"导管原位癌",
              tuijian:"全乳放疗±瘤床加量"
            },
            {
              huanzhe:"浸润癌，腋窝淋巴结阴性",
              tuijian:"1、全乳放疗（常规或大分割（首选））±瘤床加量；\n" +
                  "2、部分乳腺短程照射（APBI"
            },
            {
              huanzhe:"浸润癌，腋窝淋巴结阳性，已清扫",
              tuijian:"全乳放疗+瘤床加量+区域淋巴结放疗"
            },
            {
              huanzhe:"浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫",
              tuijian:"全乳放疗（乳房高位切线野）+瘤床加量"
            },
            {
              huanzhe:"浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫",
              tuijian:""
            },
          ],
          xinfuzhu:[
            {
              fanganmingcheng:"T-DM1",
              liacheng:"21",
              meiliaofeiyong:"45000",
              zongfeiyong:"65万",
              yibao:"否，全部自费"
            }
          ]
        },
        {
          type:'ABB',
          plan:"一旦疾病进展，预估药品（AC-THP）年总花费43万余元。患者如果有职工医保的情况下自费费用11万元（医保乙类按照30%的比例自费），使用T-DM1的患者年总花费更高达65万元，对您和家庭都是很大的压力。",
          center:' HER2阳性乳腺癌是一种特殊亚型。如果选择化疗的基础上接受靶向治疗，患者的复发风险降低了40%左右，死亡风险降低了近30%。\n' +
              '针对保乳术后，Her2阳性，激素受体阳性的患者\n' +
              '未行辅助治疗患者的可能用药及费用\n' +
              '如腋窝淋巴结阳性：',
          tabData:[
            {
              fanganmingcheng:"AC-THP",
              yaopin:"多柔比星（多美素）",
              liacheng:"21",
              meiliaofeiyong:"11200",
              zongfeiyong:"44800",
              yibao:"否，全部自费"
            },
            {
              fanganmingcheng:"AC-THP",
              yaopin:"环磷酰胺（安道生）",
              liacheng:"21",
              meiliaofeiyong:"440",
              zongfeiyong:"1760",
              yibao:"是，医保甲类"
            },  {
              fanganmingcheng:"AC-THP",
              yaopin:"序惯紫杉醇（克艾力）",
              liacheng:"7",
              meiliaofeiyong:"11900",
              zongfeiyong:"142800",
              yibao:"是，医保乙类，部分自费"
            }, {
              fanganmingcheng:"AC-THP",
              yaopin:"曲妥珠单抗",
              liacheng:"21",
              meiliaofeiyong:"7600",
              zongfeiyong:"30400",
              yibao:"是，医保乙类，部分自费"
            }, {
              fanganmingcheng:"AC-THP",
              yaopin:"帕妥珠单抗",
              liacheng:"21",
              meiliaofeiyong:"4955",
              zongfeiyong:"24775",
              yibao:"是，医保乙类，部分自费"
            }],
          hualiao:[
            {
              huanzhe:"腋窝淋巴结阳性",
              fanganmingcheng:"AC-THP",
              yaopin:"蒽环类+环磷酰胺序贯多西他赛+曲妥珠单抗+帕妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"3.5万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性",
              fanganmingcheng:"TCbHP",
              yaopin:"多西他赛+卡铂+曲妥珠单抗+帕妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"3.3万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性，伴以下危险因素之一：\n" +
                  "1、T>2cm\n" +
                  "2、其他危险因素，如ER阴性",
              fanganmingcheng:"AC-TH",
              yaopin:"蒽环类+环磷酰胺序贯多西他赛+曲妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"2.9万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性且T<2cm",
              fanganmingcheng:"TC+H",
              yaopin:"多西他赛+环磷酰胺+曲妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"2.7万元",
              zongfeiyong:"",
            }
          ],
          fangliao:[
            {
              huanzhe:"导管原位癌",
              tuijian:"全乳放疗±瘤床加量"
            },
            {
              huanzhe:"浸润癌，腋窝淋巴结阴性",
              tuijian:"1、全乳放疗（常规或大分割（首选））±瘤床加量；\n" +
                  "2、部分乳腺短程照射（APBI）"
            },
            {
              huanzhe:"浸润癌，腋窝淋巴结阳性，已清扫",
              tuijian:"全乳放疗+瘤床加量+区域淋巴结放疗"
            },
            {
              huanzhe:"浸润癌，前哨淋巴结阳性1-2枚，未行腋窝淋巴结清扫",
              tuijian:"全乳放疗（乳房高位切线野）+瘤床加量"
            },
            {
              huanzhe:"浸润癌，前哨淋巴结阳性>=3枚，未行腋窝淋巴结清扫",
              tuijian:""
            },
          ],
          xinfuzhu:[
            {
              fanganmingcheng:"T-DM1",
              liacheng:"21",
              meiliaofeiyong:"45000",
              zongfeiyong:"65万",
              yibao:"否，全部自费"
            }
          ],
          juejingqian:{
            chushi:[
              {
                huanzhe:"低复发风险患者（全满足）\n" +
                    "1、淋巴结阴性\n" +
                    "2、G1\n" +
                    "3、T≤2cm\n" +
                    "4、Ki-67低表达（≤30%）",
                tuijian:"TAM5年"
              },
              {
                huanzhe:"满足以下任意条件者\n" +
                    "1、G2或G3\n" +
                    "2、淋巴结阳性\n"+
                    "3、T>2cm",
                tuijian:"OFS+TAM5年"
              },
              {
                huanzhe:"淋巴结阳性>=4个患者",
                tuijian:"OFS+AI5年"
              },
            ],
            yanchang:[
              {
                huanzhe:"完成TAM5年初始治疗且耐受良好患者",
                tuijian:"1、未绝经患者：延长TAM治疗至10年\n" +
                    "2、绝经患者：序贯AI治疗5年"
              },
              {
                huanzhe:"完成OFS+TAM5年初始治疗且耐受良好患者",
                tuijian:"绝经患者：序贯AI治疗"
              },
              {
                huanzhe:"完成OFS+TAM5年初始治疗且耐受良好患者",
                tuijian:"绝经患者：序贯AI治疗"
              },
              {
                huanzhe:"完成OFS+AI5年初始治疗且耐受良好患者",
                tuijian:"绝经患者：AI治疗"
              }
            ]
          },
          juejinghou:{
            chushi:[
              {
                huanzhe:"全部",
                tuijian:"1、芳香化酶抑制剂（AI）5年" +
                    "2、初始使用枸橼酸他莫昔芬（TAM）治疗，可换用芳香化酶抑制剂（AI）5年"
              }
            ],
            yanchang:[
              {
                huanzhe:"完成AI5年初始治疗且耐受良好低危患者",
                tuijian:" 可停药"
              },
              {
                huanzhe:"完成AI5年初始治疗且耐受良好患者，存在以下情况：\n" +
                    "（1）淋巴结阳性\n" +
                    "（2）G3\n" +
                    "（3）其他需要行辅助化疗的危险因素，如KI-67＞30%",
                tuijian:" 可停药"
              }
            ]
          }
        },
        {
          type:'BBB',
          plan:"一旦疾病进展，预估药品（AC-THP）年总花费43万余元。患者如果有职工医保的情况下自费费用11万元（医保乙类按照30%的比例自费），使用T-DM1的患者年总花费更高达65万元，对您和家庭都是很大的压力。",
          center:' HER2阳性乳腺癌是一种特殊亚型。如果选择化疗的基础上接受靶向治疗，患者的复发风险降低了40%左右，死亡风险降低了近30%\n' +
              '针对全切术后，Her2阳性，激素受体阳性的患者\n' +
              '未行辅助治疗患者的可能用药及费用\n' +
              '如腋窝淋巴结阳性：',
          tabData:[
            {
              fanganmingcheng:"AC-THP",
              yaopin:"多柔比星（多美素）",
              liacheng:"21",
              meiliaofeiyong:"11200",
              zongfeiyong:"44800",
              yibao:"否，全部自费"
            },
            {
              fanganmingcheng:"AC-THP",
              yaopin:"环磷酰胺（安道生）",
              liacheng:"21",
              meiliaofeiyong:"440",
              zongfeiyong:"1760",
              yibao:"是，医保甲类"
            },  {
              fanganmingcheng:"AC-THP",
              yaopin:"序惯紫杉醇（克艾力）",
              liacheng:"7",
              meiliaofeiyong:"11900",
              zongfeiyong:"142800",
              yibao:"是，医保乙类，部分自费"
            }, {
              fanganmingcheng:"AC-THP",
              yaopin:"曲妥珠单抗",
              liacheng:"21",
              meiliaofeiyong:"7600",
              zongfeiyong:"30400",
              yibao:"是，医保乙类，部分自费"
            }, {
              fanganmingcheng:"AC-THP",
              yaopin:"帕妥珠单抗",
              liacheng:"21",
              meiliaofeiyong:"4955",
              zongfeiyong:"24775",
              yibao:"是，医保乙类，部分自费"
            }],
          hualiao:[
            {
              huanzhe:"腋窝淋巴结阳性",
              fanganmingcheng:"AC-THP",
              yaopin:"蒽环类+环磷酰胺序贯多西他赛+曲妥珠单抗+帕妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"3.5万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性",
              fanganmingcheng:"TCbHP",
              yaopin:"多西他赛+卡铂+曲妥珠单抗+帕妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"3.3万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性，伴以下危险因素之一：\n" +
                  "1、T>2cm\n" +
                  "2、其他危险因素，如ER阴性",
              fanganmingcheng:"AC-TH",
              yaopin:"蒽环类+环磷酰胺序贯多西他赛+曲妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"2.9万元",
              zongfeiyong:"",
            },
            {
              huanzhe:"腋窝淋巴结阳性且T<2cm",
              fanganmingcheng:"TC+H",
              yaopin:"多西他赛+环磷酰胺+曲妥珠单抗",
              liacheng:"",
              meiliaofeiyong:"2.7万元",
              zongfeiyong:"",
            }
          ],
          fangliao:[
            {
              huanzhe:"腋窝淋巴结清扫术后\n" +
                  "1、T3—T4\n" +
                  "2、腋窝淋巴结阳性",
              tuijian:"胸壁+区域淋巴结放疗"
            },
            {
              huanzhe:"前哨淋巴结阳性，未行腋窝淋巴结清扫",
              tuijian:" 胸壁+包括腋窝在内的区域淋巴结放疗"
            },
          ],
          xinfuzhu:[
            {
              fanganmingcheng:"T-DM1",
              liacheng:"21",
              meiliaofeiyong:"45000",
              zongfeiyong:"65万",
              yibao:"否，全部自费"
            }
          ],
          juejingqian:{
            chushi:[
              {
                huanzhe:"低复发风险患者（全满足）\n" +
                    "1、淋巴结阴性\n" +
                    "2、G1\n" +
                    "3、T≤2cm\n" +
                    "4、Ki-67低表达（≤30%）",
                tuijian:"TAM5年"
              },
              {
                huanzhe:"满足以下任意条件者\n" +
                    "1、G2或G3\n" +
                    "2、淋巴结阳性\n"+
                    "3、T>2cm",
                tuijian:"OFS+TAM5年"
              },
              {
                huanzhe:"淋巴结阳性>=4个患者",
                tuijian:"OFS+AI5年"
              },
            ],
            yanchang:[
              {
                huanzhe:"完成TAM5年初始治疗且耐受良好患者",
                tuijian:"1、未绝经患者：延长TAM治疗至10年\n" +
                    "2、绝经患者：序贯AI治疗5年"
              },
              {
                huanzhe:"完成OFS+TAM5年初始治疗且耐受良好患者",
                tuijian:"绝经患者：序贯AI治疗"
              },
              {
                huanzhe:"完成OFS+TAM5年初始治疗且耐受良好患者",
                tuijian:"绝经患者：序贯AI治疗"
              },
              {
                huanzhe:"完成OFS+AI5年初始治疗且耐受良好患者",
                tuijian:"绝经患者：AI治疗"
              }
            ]
          },
          juejinghou:{
            chushi:[
              {
                huanzhe:"全部",
                tuijian:"1、芳香化酶抑制剂（AI）5年" +
                    "2、初始使用枸橼酸他莫昔芬（TAM）治疗，可换用芳香化酶抑制剂（AI）5年"
              }
            ],
            yanchang:[
              {
                huanzhe:"完成AI5年初始治疗且耐受良好低危患者",
                tuijian:" 可停药"
              },
              {
                huanzhe:"完成AI5年初始治疗且耐受良好患者，存在以下情况：\n" +
                    "（1）淋巴结阳性\n" +
                    "（2）G3\n" +
                    "（3）其他需要行辅助化疗的危险因素，如KI-67＞30%",
                tuijian:" 可停药"
              }
            ]
          }
        }
      ],
      xianzhong:[
        {
          name:"乳果爱",
          age:"18-70周岁",
          insuredAmount:'10万/40万/150万',
          plan:'计划1-计划12',
          center:"1、乳腺癌首次复发医疗保险金限额（必须责任）\n" +
              "社保范围内自费费用报销；\n" +
              "社保范围外自费费用报销；\n" +
              "特定恶性肿瘤药品费用报销；\n" +
              "2、重大疾病保险金(可选责任）\n",
          time:"1年，续保需审核",
          deductible:'0免赔',
          waitingPeriod:"90天，续保成功无等待期\n"
        },
        {
          name:"粉红相伴",
          age:"18-60周岁",
          plan:"计划1：乳腺原位癌1万，乳腺恶性肿瘤10万,计划2:乳腺原位癌5万，乳腺恶性肿瘤50万\n",
          center:"首次乳腺恶性肿瘤或原位癌，报销治疗费",
          time:"1年，最高可续保到100周岁",
          deductible:'乳腺原位癌无免赔额，乳腺恶性肿瘤可选0/1万元免赔额',
          waitingPeriod:"90天"
        }
      ],
      xianzhongname:"乳果爱",
      xianzhongshow:[
        {
          name:"乳果爱",
          age:"18-70周岁",
          insuredAmount:'10万/40万/150万',
          plan:'计划1-计划12',
          center:"1、乳腺癌首次复发医疗保险金限额（必须责任）\n" +
              "社保范围内自费费用报销；\n" +
              "社保范围外自费费用报销；\n" +
              "特定恶性肿瘤药品费用报销；\n" +
              "2、重大疾病保险金(可选责任）\n",
          time:"1年，续保需审核",
          deductible:'0免赔',
          waitingPeriod:"90天，续保成功无等待期\n"
        },
      ]
    }
  },
   components: {
     layer
   },
  props: {

  },
  created() {
    this.getSessionState()
  },
   mounted() {

  },
  methods: {
    getSessionState(){
      sessionState(this.$store.getters.session).then((res)=>{
        this.show = true
        this.basic = res
        this.gender = res.gender;
        this.birthday = res.birthday;
        if(res.pathological_staging == '0期' || res.pathological_staging == '1期'){
          this.recrudescence = '<10%'
        }else if(res.pathological_staging == '2期'){
          this.recrudescence = '10~20%'
        }else if(res.pathological_staging == '3期'){
          this.recrudescence = '30~40%'
        }else if(res.pathological_staging == '4期'){
          this.recrudescence = '可能随时复发'
        }
        if(res.pathological_staging == '0期' || res.pathological_staging == '1期'){
          this.survivalRate = '99％'
        }else if (res.pathological_staging == '2期' || res.pathological_staging == '3期'){
          this.survivalRate = '86％'
        }else {
          this.survivalRate = '27％'
        }
        if(res.her2 == '阴性' && res.hormone_receptor == '阴性' && res.operation_method == '保乳'){
          this.answer = "AAA"
        }
        if(res.her2 == '阴性' && res.hormone_receptor == '阴性' && res.operation_method == '全切'){
          this.answer = "BAA"
        }
        if(res.her2 == '阴性' && res.hormone_receptor == '阳性' && res.operation_method == '保乳'){
          this.answer = "AAB"
        }
        if(res.her2 == '阴性' && res.hormone_receptor == '阳性' && res.operation_method == '全切'){
          this.answer = "BAB"
        }
        if(res.her2 == '阴性' && res.hormone_receptor == '阴性' && res.operation_method == '全切'){
          this.answer = "BBA"
        }
        if(res.her2 == '阳性' && res.hormone_receptor == '阴性' && res.operation_method == '保乳'){
          this.answer = "ABA"
        }
        if(res.her2 == '阳性' && res.hormone_receptor == '阳性' && res.operation_method == '保乳'){
          this.answer = "ABB"
        }
        if(res.her2 == '阳性' && res.hormone_receptor == '阳性' && res.operation_method == '全切'){
          this.answer = "BBB"
        }
      })
    },
    onChange(){
      this.xianzhongshow =[]
      if(this.xianzhongname == '乳果爱'){
        this.xianzhongshow = this.xianzhong.filter(item => item.name=== '粉红相伴')
        this.xianzhongname = '粉红相伴'
      }else if(this.xianzhongname == '粉红相伴'){
        this.xianzhongshow = this.xianzhong.filter(item => item.name=== '乳果爱')
        this.xianzhongname = '乳果爱'
      }
    },
    onLayer(){
      this.layerShow = true
      const TIME_COUNT = 3;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.layerShow = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }

    },
    close(){
      clearInterval(this.timer);
      this.layerShow = false
      this.timer = null;

    }
  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.disease{
  width: 100%;
  height: 100%;
  background: #F3F4FC;
  position: fixed;
  left: 0;
  top: 0;
  overflow: scroll;
  .tab-box{
    .tab-title{
      display: flex;
      margin:.4rem .3rem;
      div{
        width: 1.46rem;
        height: .64rem;
        background: rgba(196, 196, 196, 0.5);
        border-radius: .16rem;
        color: #FFFFFF;
        line-height: .64rem;
        text-align: center;
        margin-right: .24rem;
        font-size: .24rem;
      }
      .active{
        background: linear-gradient(165deg, #55ACFF 0%, #169DFF 100%);
      }
    }
    .box{
      margin: .3rem;
      background: linear-gradient(132deg, #FDFFFF 10%, rgba(255, 233, 246, 0.6) 34%, #DDF0FF 100%);
      border-radius: .24rem 0 .24rem .24rem;
      .list-top{
        width: 100%;
        height: .85rem;
        background: url("../../../src/assets/images/list-top.png") no-repeat;
        background-size: 100% 100%;
        font-weight: 500;
        color: #131313;
        line-height: .85rem;
        padding-left: .3rem;
        span{
          float: right;
          line-height: .3rem;
          color: #1675FF;
          font-size: .24rem;
          img{
            width: .2rem;
            height: .2rem;
            float: left;
            margin-right: .05rem;
            margin-top: .04rem;
          }
        }
      }
      .title{
        font-weight: 500;
        color: #131313;
        margin-bottom: .4rem;
        margin-top: .6rem;
      }
      .list{
        font-size: .28rem;
        color: #131313;
        padding: .5rem .3rem;
      }
      .list-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: .24rem;
        color: #666666;
      }
    }
  }
}
.table-box{
  width: 100%;
  overflow-x: scroll;
  .table{
    table{
      width: 100%;
      font-size: .24rem;
      border-collapse:collapse;
      background: #fff;
      th{
        background: rgba(241, 248, 255, 1);
        padding: .3rem;
        border:1px rgba(146, 157, 163, .2) solid;
      }
      td{
        border:1px rgba(146, 157, 163, .2) solid;
        padding: .3rem;
        display:table-cell;
        vertical-align:middle
      }
    }
  }

}
.tab-box{
  width: 100%;
  overflow-x: scroll;
  table{
    width: 100%;
    border-collapse:collapse;
    background: #fff;
  }
  th{
    background: #F1F8FF;
    //line-height: .72rem;
    font-size: .24rem;
    color: #131313;
    padding: .2rem 0;
  }
  td{
    font-size: .24rem;
    padding: .28rem;
    border:1px solid rgba(146, 157, 163, .04);
    line-height: .4rem;
    p{
      line-height: .4rem;
    }
  }
}
.my-swipe .van-swipe-item {
  width: 100%;
  height: 3.35rem;
  img{
    width: 100%;
  }
}
.line{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: .28rem;
  color: #131313;
  margin-bottom:.4rem;
  :last-child{
    font-weight: 500;
    color: #1F64EC;
  }
}
.jieshao{
  font-size: .24rem;
  margin-bottom: .3rem;
}
.huadong{
  width: 100%;
  text-align: center;
  font-size: .12rem;
  color: #C4C4C4;
  line-height: 30px;
}
.baozhang{
  font-size: .28rem;
  line-height: .4rem;
  color: #666666;
  margin-bottom: .3rem;
  .title{
    color: #131313;
    margin-bottom: .3rem;
  }
}
.list-tip{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: .28rem;
  color: #666;
  margin-bottom:.25rem;
  :last-child{
    font-weight: 500;
    color: #131313;
  }
}
.tips-top{
  font-size: .24rem;
  padding: .15rem .3rem 0 .3rem;
  color: #1F64EC;
}
.layer-box{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99;
  background: rgba(0,0,0, 0.5);
}
.close{
  width: 100%;
  text-align: center;
  margin-top: .2rem;
}

</style>

