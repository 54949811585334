<template>
  <div class="layer">
    <div class="layer-img">
      <img src="../../assets/images/img.png">
    </div>
    <div class="line">
      <div>姓名：</div>
      <div class="line-right">郑京京</div>
    </div>
    <div class="line">
      <div>性别：</div>
      <div class="line-right">女</div>
    </div>
    <div class="line">
      <div>发证日期：</div>
      <div class="line-right">2020-02-25</div>
    </div>
    <div class="line">
      <div>资格证书号码：</div>
      <div class="line-right">21009111010580002020003077</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'layer',
  data() {
    return {}
  },
   components: {

   },
  props: {

  },
  created() {

  },
   mounted() {
  },
  methods: {

  },
   destroyed () {

    },

  updated(){

  },
}
</script>

<style lang="scss" scoped>
.layer{
  background:url("../../assets/images/layer.png") no-repeat;
  background-size: 100% 100%;
  width: 5.68rem;
  height: 6.44rem;
  margin: 3rem auto 0 auto;
  padding: 0  .2rem;
}
.layer-img{
  width: 100%;
  height: 3.6rem;
  text-align: center;
  padding-top: .53rem;
  img{
    width: 70px;
    height: 135px;
  }
}
.line{
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: .6rem;
  color: #C4C4C4;
  font-size: .24rem;
  .line-right{
    color: #131313;
  }
}
</style>

